.whole{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 96px;
}
.headerDiv{
    padding: 28px;
    width: 100%;
    margin: 25px auto 0;
    max-width: 1870px;
    border-radius: 45.072px;
    background: #597F94;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 606px;
    width: 100%;
    margin-right: 50px;
}

.title{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-bottom: 22px;
}

.text{
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 163%;
    letter-spacing: 0.18px;
    margin-bottom: 24px;
}

.subTitle{
    color: #084EE4;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 164.5%;
    letter-spacing: 0.18px;
    margin-right: 8px;
}
.right{
   max-width: 606px;
    width: 100%;
}
.imgStyle{
    border-radius: 22px;
}