.whole{
    background: #71ADCC;
    padding-top: 55px;
    padding-bottom: 55px;
}

.text{
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins_Medium,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 153%;
    letter-spacing: 0.24px;
    width: 100%;
}

.info{
    color: #084EE4;
    font-family: Poppins_Medium,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 153%;
    letter-spacing: 0.24px;
    margin-left: 7px;
}