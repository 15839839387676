.btnStyle{
    background:transparent;
    border-radius: 114px;
    border: 1px solid #FFF;
    padding: 10px 22px;
    cursor: pointer;
    color: #FFF;
    font-family: Poppins_Regular,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}
.btnStyle:disabled{
    cursor: not-allowed;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

/*@media only screen and (min-width:850px) and (max-width: 1024px) {*/
/*    .btnStyle{*/
/*        line-height: 20px;*/
/*        width: 400px !important;*/
/*    }*/
/*}*/

/*@media only screen and (min-width:768px) and (max-width: 850px) {*/
/*    .btnStyle{*/
/*        line-height: 20px;*/
/*        width:300px !important;*/
/*    }*/
/*}*/
