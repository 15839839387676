.longWhole{
    padding-top: 44px;
    padding-bottom: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.longTitle{
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 158%;
    letter-spacing: 0.24px;
    margin-bottom: 44px;
    max-width: 1232px;
    width: 100%;
}
.formWhole{
    max-width: 879px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.formDiv{
    max-width: 879px;
    width: 100%;
    padding:20px;
    border-radius: 22px;
    background: #ECF4F9;
    margin-bottom: 48px;
}
.formTitle{
    margin-bottom: 20px;
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.2px;
}
.ratios{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.ratioInput{
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.single{
    color: #000;
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.16px;
    margin-right: 48px;
    display: flex;
    align-items: center;
}
.unit{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.next{
    padding: 14px 36px;
    margin: auto;
    border-radius: 114px;
    background: linear-gradient(128deg, #3C9176 -21.79%, rgba(58, 125, 226, 0.50) 164.28%);
    cursor: pointer;
    max-width: fit-content;
    color: #FFF;
    font-family: Poppins_Bold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
}
.selectError{
    max-width:209px !important;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 2px solid #CB2D25 !important;
    border-radius: 59px;
    margin-bottom: 40px;
}
.errorText{
    font-family:  Poppins_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #CB2D25 !important;
    margin-top: -20px;
}
.control{
    position: relative;
    width: 100%;
    max-width: 209px;
}
.selectStyle{
    width: 100%;
    max-width: 209px;
    padding: 12px 20px;
    color: rgba(16, 47, 94, 0.60);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.16px;
    border-radius: 59px;
    background: #FFF;
    border: none
}
.selectPlaceholderStyle{
    width: 100%;
    max-width: 209px;
    padding: 12px 20px;
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.16px;
    border-radius: 59px;
    background: #FFF;
    border: none
}
.selectSearchError{
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 2px solid #CB2D25 !important;
    border-radius: 59px;
    margin-bottom: 40px;
}


