.left{
    display: flex;
    flex-direction: column;
    max-width: 606px;
    width: 100%;
    margin-right: 20px;
}

.title{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    margin-bottom: 10px;
}
.text{
    color:#102F5E;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 154.023%;
}

.img{
    border-radius: 22px;
}