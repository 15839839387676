.whole{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #EEE;
    padding-top: 111px;
    padding-bottom: 111px;
    margin-bottom: 96px;
}

.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left{
    display: flex;
    flex-direction: column;
}

.leftTitle{
    color: #084EE4;
    font-family: Poppins_Regular,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    margin-bottom: 14px;
}

.leftText{
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    max-width: 495px;
    margin-bottom: 5px;
}

.right{
    max-width: 710px;
    width: 100%;
}

.videoDiv{
    width: 710px;
    height: 382px;
    position: relative;
}

.btnStyle{
    border: none;
}

.youtubeBtn{
    position: absolute;
    top: 50%;
    left: 47%;
    cursor: pointer;
}

@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .whole{
        margin-bottom: 50px;
        padding: 50px 50px;
    }
    .left{
        margin-right: 48px;
    }
    .leftTitle{
        font-size:20px;
    }
}