.whole{
    padding-top: 60px;
    padding-bottom: 60px;
    background: #71ADCC;
}

.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 678px;
    width: 100%;
}

.blueTitle{
    color: #084EE4;
    font-family: Poppins_Regular,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.title{
    color: #FFF;
    font-family: Poppins_SemiBold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.023%;
    margin-bottom: 20px;
}

.subTitle{
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins_Medium,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 153%;
    margin-bottom: 14px;
}

.text{
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins_Regular,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    margin-bottom: 12px;
}

.right{
    margin-left: 28px;
}
.here{
    color: #084EE4;
    font-family: Poppins_Regular,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-decoration-line: underline;
    margin-left: 5px;
    cursor: pointer;
}

.donateBtn{
    padding: 14px 36px;
    color: #54999F;
    font-family: Poppins_Bold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
    border-radius: 114px;
    background: #FFF;
    cursor: pointer;
    border: none;
    max-width: fit-content;
}
.donateBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}


@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .whole {
        padding: 50px;
    }
    .left{
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
    }

    .blueTitle{
        font-size: 20px;
    }

    .title{
        font-size: 20px;
        margin-bottom: 12px;
    }

    .subTitle{
        font-size: 20px;
        margin-bottom: 14px;
    }

    .text{
        color: rgba(255, 255, 255, 0.80);
        font-family: Poppins_Regular,sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136.023%;
        margin-bottom: 12px;
    }

    .right{
        margin-left: 22px;
    }

    .donateBtn{
        font-size: 20px;
    }
}




