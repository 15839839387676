.longWhole{
    padding-top: 44px;
    padding-bottom: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.longTitle{
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 158%;
    letter-spacing: 0.24px;
    margin-bottom: 44px;
    max-width: 1232px;
    width: 100%;
}
.formWhole{
    max-width: 879px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.formDiv{
    max-width: 879px;
    width: 100%;
    padding:20px;
    border-radius: 22px;
    background: #ECF4F9;
    margin-bottom: 48px;
}
.checkboxes{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.checkboxRowBelowPart{
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.checkboxLabel{
    color: rgba(16, 47, 94, 0.60);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%; /* 32.905px */
    letter-spacing: 0.2px;
    margin-left: 8px;
    white-space: nowrap;
}
.checkboxInput{
    width: 21px !important;
    height: 21px  !important;
    margin-right: 7px;
}
.formTitle {
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    margin-bottom: 20px;
    letter-spacing: 0.2px;
}


.checkBtn{
    padding: 14px 36px;
    border-radius: 114px;
    background: linear-gradient(128deg, #3C9176 -21.79%, rgba(58, 125, 226, 0.50) 164.28%);
    border: none;
    color: #FFF;
    font-family: Poppins_Bold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
    cursor: pointer;
}
.control{
    margin-bottom: 24px;
    width: 100%;
}
.selectLabel {
    display: block;
    color:rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.16px;
    /*color: #393122;*/
    margin-bottom: 4px;
}

.selectInputStyle{
    max-width: 403px;
    width: 100%;
    padding: 12px 20px;
    color:rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.16px;
    border-radius: 59px;
    background: #FFF;
    border: none
}
.row{
    display: flex;
    align-items: center;
    gap:32px
}

