.formDiv{
    border-radius: 22px;
    background: #ECF4F9;
    padding:60px;
    max-width: 827px;
    width: 100%;
    margin: -280px auto 96px;
}

.title{
    max-width:707px;
    width: 100%;
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

.subtitle{
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.2px;
    margin-bottom: 29px;
}

.upRight{
    max-width: 502px;
    width: 100%;
    padding: 20px;
    border-radius: 22px;
    background: #ECF4F9;
    height: 752px;
}

.text{
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.2px;
}

.checkBtn{
    padding: 14px 36px;
    border-radius: 114px;
    background: linear-gradient(128deg, #3C9176 -21.79%, rgba(58, 125, 226, 0.50) 164.28%);
    border: none;
    color: #FFF;
    font-family: Poppins_Bold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
    cursor: pointer;
}

.checkBtn:disabled{
    cursor: not-allowed;
}

.downPart{
    width: 100%;
    border-radius: 22px;
    background: #ECF4F9;
    padding-top: 82px;
    padding-bottom: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inside{
    max-width: 928px;
    width: 100%;
    color: #102F5E;
    text-align: center;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 176.523%;
    letter-spacing: 0.24px;
}
.checkboxRow{
    margin-bottom: 30px;
    display: flex;
}
.checkboxLabel{
    color: rgba(16, 47, 94, 0.60);
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.24px;
    margin-left: 8px;
    max-width: 678px;
    width: 100%;
}
.checkboxInput{
    width: 21px !important;
    height: 21px  !important;
    margin-left: 12px;
}

.selectSearchError{
    max-width:827px !important;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 2px solid #CB2D25 !important;
    border-radius: 59px;
    margin-bottom: 40px;
}
.errorText{
    font-family:  Poppins_Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #CB2D25 !important;
    margin-top: -20px;
}

.titleForm{
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin-bottom: 26px;
    text-transform: uppercase;
}