.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 96px;
    padding-bottom: 94px;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 631px;
    width: 100%;
    margin-right: 25px;
}
.title{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-bottom: 22px;
}
.text{
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 163%;
    letter-spacing: 0.16px;
    margin-bottom: 24px;
}
.right{
    max-width: 606px;
    width: 100%;
}

.imgStyle{
    border-radius: 22px;
    background: #5F8498;
}