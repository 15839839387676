.whole{
    padding-top: 60px;
    padding-bottom: 60px;
    background: #71ADCC;
}

.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 678px;
    width: 100%;
}
.blueTitle{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-bottom: 12px;
}
.blueSubTitle{
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins_Medium,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 153%;
    letter-spacing: 0.24px;
    padding-bottom: 22px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.30);
    margin-bottom: 28px;
}
.blueText{
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins_Bold,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%; /* 21.764px */
    letter-spacing: 1.28px;
    margin-bottom: 16px;
}
.btnDiv{
    margin-top: 4px;
    margin-bottom: 18px;
}

.mailRow{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-top: -12px;
}