.whole{
    padding: 28px;
    width: 100%;
    max-width: 1920px;
}

.main{
    background-image: url("../../../assets/images/Image1.png");
    background-repeat: no-repeat;
    width: 100%;
    padding: 25px 38px 38px 38px;
    max-width: 1864px;
    height: 817px;
    border-radius: 45px;
}

.middle{
    display: flex;
    margin-top: 60px;

}
.middleLeft{
    max-width: 246px;
    width: 100%;
    margin-right: 82px;
}
.middleRight{
   display: flex;
    flex-direction: column;
}

.middlePart1{
    max-width: 1235px;
    width: 100%;
    color: #FFF;
    font-family: Poppins_Bold,sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130.023%;
}
.middlePart{
    display: flex;
    align-items: center;
    margin-top: 21px;
}

.middlePart2{
    color: #FFF;
    font-family: Poppins_Medium,sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.523%;
    margin-right: 150px;
}

.btnStyle{
    padding: 14px 36px;
    border-radius: 114px;
    background: #FFF;
    color: #54999F;
    font-family: Poppins_Bold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    cursor: pointer;
    margin-top: 24px;
    width: fit-content;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.belowWhole{
    margin-top: 150px;
}

.healthyText{
    margin-left: 12px;
    color: #102F5E;
    font-family: Poppins_Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.btnMedical{
    padding: 10px 22px;
    border-radius: 114px;
    border: 1px solid #077E1A;
    color: #102F5E;
    font-family: Poppins_Regular,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    cursor: pointer;
}

.btnMedical:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.text{
    color: #102F5E;
    font-family: Poppins_Medium,sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.023%;
    margin-left: -28px;
    margin-top: 5px;
}

.below{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.belowRight{
    border-radius: 30px;
    border: 0.5px solid #3C9176;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(34px);
    padding: 50px 25px;
    max-width: 331px;
    width: 100%;
    margin-top: 30px;
}

.belowTitle{
    color: #102F5E;
    font-family: Poppins_Medium,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 146.023%;
    margin-bottom: 12px;
}
.belowText{
    color: rgba(55, 55, 55, 0.60);
    font-family: Poppins_Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
}
.donation{
    margin-top: 10px;
}

@media only screen and (min-width:1440px) and (max-width: 1520px) {
    .belowRight{
        padding: 25px;
        margin-top: 22px;
    }
}

@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .middlePart1{
        font-size:32px;
    }
    .middlePart2{
        font-size:24px;
    }
    .btnStyle{
        font-size: 20px;
        margin-top: 22px;
    }
    .text{
        font-size: 32px;
        margin-left: -28px;
        margin-top: 5px;
        max-width: 380px;
        width: 100%;
    }
    .belowRight{
        padding: 18px;
        max-width: 450px;
        width: 100%;
        margin-top: -50px;
        margin-bottom: 40px;
    }
    .belowWhole{
        margin-top: 70px;
    }
    .middle{
        margin-top: 40px;
    }
    .belowText{
        margin-bottom: 0;
    }
    .belowTitle{
        font-size: 20px;
        margin-bottom: 8px;
    }
    .main{
        height:auto;
    }
}