.main{
    margin-top: 96px;
    margin-bottom: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left{
    max-width: 808px;
    width: 100%;
    margin-right: 27px;
}
.title{
    margin-bottom: 12px;
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
}
.subTitle{
    color: #102F5E;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 148.523%;
    margin-bottom: 28px;
}
.row{
    display: flex;
    margin-bottom: 30px;
}

.rowLeft{
    max-width: 31px;
    width: 100%;
    height: 31px;
    margin-right: 20px;
    border-radius: 31px;
    background: #3C7AFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Inter_Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.rowRight{
    color: #28292F;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px
}