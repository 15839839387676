.whole{
    padding: 28px;
    width: 100%;
    max-width: 1920px;
}

.main{
    background-image: url("../../../assets/images/Image18.png");
    background-repeat: no-repeat;
    width: 100%;
    padding: 25px 38px 38px 38px;
    max-width: 1864px;
    height: 810px;
    border-radius: 46px;
}

.mainInside{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.inside{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 246px;
    width: 100%;
}


.btnDonate{
    padding: 14px 36px;
    border-radius: 114px;
    background: #FFF;
    border: none;
    color: #54999F;
    font-family: Poppins_Bold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
    margin-top: 37px;
    max-width: fit-content;
    cursor: pointer;
}
.btnDonate:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.middle{
    display: flex;
    flex-direction: column;
    max-width: 1328px;
    width: 100%;
}

.title{
    margin-bottom: 10px;
    color: #FFF;
    font-family: Poppins_Medium, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.523%;
    letter-spacing: 0.48px;
}
.text{
    margin-bottom: 22px;
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins_Medium,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.023%;
    letter-spacing: 0.24px;
}
