.modalInside{
    display: flex;
    flex-direction: column;
}

.closingDiv{
    display: flex;
   align-self: flex-end;
    margin-bottom: 62px;
}

.closingIcon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.modalText{
    max-width: 928px;
    width: 100%;
    color: #102F5E;
    text-align: center;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 176.523%;
    letter-spacing: 0.24px;
    display: flex;
    align-self: center;
    margin-bottom: 100px;
    white-space: pre-wrap;
}