.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 96px;
    margin-bottom: 36px;
}

.left{
    max-width: 580px;
    width: 100%;
    margin-right: 40px;
    height: 100%;
}

.img{
    border-radius: 22px;
    background: #5F8498;
}
.right{
    display: flex;
    flex-direction: column;
    max-width: 631px;
    width: 100%;
}

.title{
    color: #084EE4;
    font-family: Poppins_Regular,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-bottom: 24px;
}
.text{
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 163%;
    margin-bottom: 24px;
    max-width: 650px;
    width: 100%;
}

.titleBelow{
    color:  #102F5E;
    font-family: Inter_Medium,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    margin-bottom: 14px;
}

.textBelow{
    color: #28292F;
    font-family: Inter_Light,sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
}

.imageStyle{
    height: 216px;
    object-fit: cover;
    max-width: 580px;
    width: 100%;
    border-radius: 22px;
}

.below{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
}