.title{
    margin:96px auto 96px;
    max-width: 814px;
    color: #545454;
    text-align: center;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .title{
        margin:50px auto 50px;
        font-size: 20px;
    }
}