.subTitle{
    color:#102F5E;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 154.023%;
    margin-bottom: 24px;
    margin-top: 67px;
}

.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap : 20px;
    margin-bottom: 30px;
}

.left{
    max-width: 605px;
    width: 100%;
}

.imgStyle{
    max-width: 605px;
    width: 100%;
    border-radius: 22px;
}

.right{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 620px;
    width: 100%;
}

.text{
    color: #28292F;
    font-family: Inter_Light,sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
}

.below{
    margin-top: 60px;
    margin-bottom: 65px;
}

.row{
    display: flex;
    margin-bottom: 30px;
}

.rowLeft{
    max-width: 31px;
    width: 100%;
    height: 31px;
    border-radius: 31px;
    background: #3C7AFF;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Inter_Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.rowRight{
    color: #28292F;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px
}