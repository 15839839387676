.whole{
    padding: 28px;
    width: 100%;
    max-width: 1920px;
}

.main{
    background-image: url("../../../assets/images/Image21.png");
    background-repeat: no-repeat;
    width: 100%;
    padding: 25px 38px 38px 38px;
    max-width: 1864px;
    height: 817px;
    border-radius: 45px;
}

.middle{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1133px;
    width: 100%;
    margin: 200px auto 0;
}

.title{
    margin-bottom: 16px;
    color: #FFF;
    font-family: Poppins_Medium, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.523%;
    letter-spacing: 0.24px;
    text-align: center;
}

