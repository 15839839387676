.longWhole{
    padding-top: 44px;
    padding-bottom: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.longTitle{
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 158%;
    letter-spacing: 0.24px;
    margin-bottom: 44px;
    max-width: 1232px;
    width: 100%;
}
.formWhole{
    max-width: 879px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.formDiv{
    max-width: 879px;
    width: 100%;
    padding:20px;
    border-radius: 22px;
    background: #ECF4F9;
    margin-bottom: 48px;
}

.unit{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.subTitle{
    margin-bottom: 12px;
    color: rgba(16, 47, 94, 0.80);
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.2px;
}

.iconDiv{
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.btnText{
    color: #FFF;
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    letter-spacing: 0.16px;
}
.rowLeft{
    padding: 12px 36px;
    border-radius: 114px;
    background: linear-gradient(128deg, #3C9176 -21.79%, rgba(58, 125, 226, 0.50) 164.28%);
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
}

.next{
    padding: 14px 36px;
    margin: auto;
    border-radius: 114px;
    background: linear-gradient(128deg, #3C9176 -21.79%, rgba(58, 125, 226, 0.50) 164.28%);
    cursor: pointer;
    max-width: fit-content;
    color: #FFF;
    font-family: Poppins_Bold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
}