.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 96px;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 606px;
    width: 100%;
}

.right{
    max-width: 606px;
    width: 100%;
    margin-left: 20px;
}

.imgStyle{
    max-width: 606px;
    width: 100%;
    border-radius: 22px;
}

.title{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
}

.subTitle{
    color:  #102F5E;
    font-family: Poppins_SemiBold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.023%;
    margin-bottom: 22px;
}

.text{
    margin-bottom: 24px;
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 163%;
    letter-spacing: 0.16px;
}