.title{
    color: #084EE4;
    font-family: Poppins_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.023%;
    text-transform: uppercase;
    margin-top: 44px;
    margin-bottom: 12px;
}

.text{
    color: rgba(55, 55, 55, 0.90);
    font-family: Poppins_Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 173%;
    letter-spacing: 0.16px;
    margin-bottom: 40px;
}

.checkboxRowBelow{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.checkboxLabel{
    color: rgba(16, 47, 94, 0.60);
    font-family: Poppins_Regular, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 150% */
    letter-spacing: 0.18px;
    margin-left: 8px;
    white-space: nowrap;
}
.checkboxInput{
    width: 21px !important;
    height: 21px  !important;
    margin-right: 7px;
}
.titlesRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
}

.titlesRowInside{
    color:  #102F5E;
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 158%;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
}
.signature{
    margin-left: 12px;
    border-radius: 59px;
    border: 1px solid rgba(16, 47, 94, 0.40);
    background: #FFF;
    width: 282px;
    height: 50px;
}

.submitBtn{
    padding: 14px 36px;
    border-radius: 114px;
    background:linear-gradient(128deg, #3C9176 -21.79%, rgba(58, 125, 226, 0.50) 164.28%);
    color: #FFF;
    font-family: Poppins_Bold,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 136.023%;
    letter-spacing: 0.24px;
    max-width: fit-content;
    cursor: pointer;
    margin-bottom: 48px;
    border:none;
}

.linkstyle{
    color: #102F5E;
    font-family: Poppins_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.523%;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    margin-bottom: 128px;
    cursor: pointer;
}
