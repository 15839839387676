.whole{
    max-width: 1429px;
    width: 100%;
    margin: 0 auto 100px;
}
.title{
    margin-top: 96px;
    margin-bottom: 29px;
    color: #080D0E;
    font-family: Poppins_Bold,sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 128.9%;
}
.main{
    display: flex;
    gap: 20px;
}

.inside{
    max-width: 464px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mainTitle{
    color: rgba(8, 8, 8, 0.90);
    font-family: Inter_SemiBold,sans-serif;
    font-size: 28.47px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.subTitle{
    color: rgba(8, 8, 8, 0.90);
    font-family: Inter_Regular, sans-serif;
    font-size: 19.688px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 22px;
}
.description{
    color: #5F5A69;
    font-family: Inter_Medium,sans-serif;
    font-size: 17.156px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 12px;
}
.view{
    color: #084EE4;
    font-family: Inter_Medium,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    text-decoration-line: underline;
    cursor: pointer;
}

.mainImg{
    max-width: 464px;
    height: 481px;
    object-fit: cover;
}